/* tslint:disable max-line-length | no-trailing-whitespace */
import * as React from 'react';
import { Classable } from '@shapeable/types';

export const ApplicationLogo: React.FC<Classable> = ({ className }) => (
  <svg className={className} version="1.1" x="0px" y="0px" viewBox="0 0 820.9 240.7">
    <g id="Layer_1">
	<g>
		<g>
			<path fill="#09672F" d="M412.1,1.6h11.7v113.6h-11.7V1.6z"/>
			<path fill="#09672F" d="M507.7,115.2h-11.1v-12.2c-5.4,9.8-14,15-24.3,15c-19.2,0-32.1-14.3-32.1-36.2
				c0-22.5,12.8-36.2,31.5-36.2c11.3,0,19.5,5.1,24.6,15V48.2h11.4V115.2z M474.2,57.2c-13.8,0-21.9,8.9-21.9,24.2
				c0,16.2,8.1,25.4,22.4,25.4c13.5,0,21.9-8.9,21.9-24.3C496.6,66.8,487.2,57.2,474.2,57.2z"/>
			<path fill="#09672F" d="M524.3,48.2h10.2v14c6-11.4,12.3-16.2,22.5-16.2c2.7,0,5.3,0.5,7.8,1.3l-3.9,10.4c-2.6-0.9-5-1.3-7.1-1.3
				c-12.1,0-18.2,8.7-18.2,26.3v32.7h-11.4V48.2z"/>
			<path fill="#09672F" d="M577.9,97.4c3.8,7.2,9.4,10.7,17.1,10.7c7.7,0,12.6-4.2,12.6-10c0-5.1-3.5-7.8-10.5-10.2
				c-16.5-5.7-26.4-8.6-26.4-21.8c0-11.7,9.4-20.3,23.7-20.3c11.6,0,20.3,4.8,23.9,13.4l-9.3,5.7c-2.7-6.2-8.2-9.6-15.3-9.6
				c-6.7,0-11.4,4.2-11.4,10.1c0,7,6.2,8,17.3,11.6c13.1,4.2,19.5,9,19.5,20c0,12.5-9.6,21.3-24.5,21.3c-12.4,0-21.2-4.7-26.4-14.1
				L577.9,97.4z"/>
			<path fill="#09672F" d="M379.8,169.1H391v10.3c5.9-8.7,14-12.7,24.5-12.7c15.6,0,25.2,8.7,25.2,23.9v45.6H429v-39.5
				c0-13.5-5.5-20.1-17.5-20.1c-6.8,0-12.6,2.5-16.4,7.2c-3.9,4.8-3.9,10.4-3.9,17.7v34.7h-11.4V169.1z"/>
			<path fill="#09672F" d="M460.6,218.3c3.8,7.2,9.4,10.7,17.1,10.7s12.6-4.2,12.6-10.1c0-5.1-3.5-7.8-10.5-10.2
				c-16.5-5.7-26.4-8.6-26.4-21.8c0-11.7,9.4-20.2,23.7-20.2c11.6,0,20.3,4.8,23.9,13.4l-9.3,5.7c-2.7-6.2-8.3-9.6-15.3-9.6
				c-6.7,0-11.4,4.2-11.4,10.1c0,7,6.2,8,17.3,11.6c13.1,4.2,19.5,9,19.5,20c0,12.4-9.6,21.3-24.5,21.3c-12.4,0-21.2-4.7-26.4-14.1
				L460.6,218.3z"/>
			<path fill="#09672F" d="M512,178.5v-9.4h12.3v-27H536v27h12.3v9.4H536v57.6h-11.7v-57.6H512z"/>
			<rect x="560.3" y="133.2" fill="#09672F" width="14.7" height="14.7"/>
			<path fill="#09672F" d="M561.9,169.1h11.6v67.1h-11.6V169.1z"/>
			<path fill="#09672F" d="M586.9,178.5v-9.4h12.3v-27h11.7v27h12.3v9.4h-12.3v57.6h-11.7v-57.6H586.9z"/>
			<path fill="#09672F" d="M648.5,202.1c0,17.7,4.1,26,18.8,26s18.6-8.3,18.6-26v-33h11.7v39.2c0,20.3-10.5,30.8-30.6,30.8
				c-19.8,0-30.5-10.5-30.5-30.8v-39.2h12V202.1z"/>
			<path fill="#09672F" d="M711,178.5v-9.4h12.3v-27H735v27h12.3v9.4H735v57.6h-11.7v-57.6H711z"/>
			<path fill="#09672F" d="M765.9,204.4c0,0.3,0,0.8,0,1.1c0,14.6,9.3,24,22.2,24c9,0,16.2-4.8,21.8-14.6l8.7,5.9
				c-6.8,12.2-17.3,18.3-31.1,18.3c-20.4,0-34.2-14.7-34.2-36.6c0-21.2,14-35.9,33.5-35.9c19.7,0,32.3,13.4,32.3,35.6
				c0,0.8,0,1.5,0,2.3H765.9z M786.3,176.6c-11,0-19.2,7-20.4,18.5h40.8C806,183.2,798.5,176.6,786.3,176.6z"/>
			<path fill="#09672F" d="M379.8,1.6h11.7v113.6h-11.7V1.6z"/>
			<g>
				<path fill="#09672F" d="M347.1,37.9h11.6v198.2h-11.6V37.9z"/>
				<rect x="345.4" y="1.6" fill="#09672F" width="14.9" height="14.9"/>
			</g>
			<path fill="#09672F" d="M238.5,2h14.6l33.4,87.1L319.8,2h14.6l-45.1,111.3c-1,2.6-4.7,2.6-5.7,0L238.5,2z"/>
		</g>
		<path fill="#09672F" d="M143.8,169.2L143.8,169.2c-5,5.7-12,5.8-17,0.2l-37.8-41.8c-5.4-6-13-5.4-18,1.4l-17.5,20.3l-11.1,12.8
			c15.2,25.7,43.1,43.1,75.2,43.1c22.6,0,43.2-8.7,58.7-22.8L160,168.2C155,163.4,148.5,163.9,143.8,169.2z"/>
		<path fill="#09672F" d="M91.2,84.6l33.2,60.5c4.3,7.8,12.5,7.9,16.9,0.2c4-7.1,11.4-7.7,15.9-1.3l23.2,34.7
			c15.2-15.7,24.6-37.1,24.6-60.7c0-48.2-39.1-87.3-87.3-87.3c-48.2,0-87.3,39.1-87.3,87.3c0,13.4,3.1,26,8.5,37.3l35.3-70.5
			C78.4,76.7,86.8,76.6,91.2,84.6z"/>
		<path fill="#FFFFFF" d="M180.3,178.6L157.2,144c-4.6-6.4-11.9-5.8-15.9,1.3c-4.4,7.7-12.6,7.6-16.9-0.2L91.2,84.6
			c-4.4-8-12.8-7.9-17.1,0.2l-35.3,70.5c1.1,2.4,2.3,4.7,3.7,6.9l11.1-12.8l17.5-20.3c4.9-6.8,12.5-7.4,18-1.4l37.8,41.8
			c5,5.6,12,5.5,17-0.2v0c4.7-5.4,11.2-5.8,16.2-1.1l16.3,14.3C177.7,181.2,179,180,180.3,178.6z"/>
		<g>
			<path fill="#09672F" stroke="#09672F" strokeWidth="1.4173" strokeMiterlimit="10" d="M117.7,3.7c54.3-2.4,105,39.2,114,92.7
				c1.3,7.5,1.9,15.1,1.7,22.7H230c-0.2-7.4-1.1-14.7-2.7-21.8c-8.8-43.2-44.5-79.1-87.8-88.1c-7.2-1.6-14.5-2.6-21.9-2.8V3.7
				L117.7,3.7z"/>
		</g>
		<g>
			<path fill="#09672F" stroke="#09672F" strokeWidth="1.4173" strokeMiterlimit="10" d="M117.7,234.4
				c-54.3,2.4-105-39.2-114-92.7c-1.3-7.5-1.9-15.1-1.7-22.7h3.5c0.2,7.4,1.1,14.7,2.7,21.8c8.8,43.2,44.5,79.1,87.8,88.1
				c7.2,1.6,14.5,2.6,21.9,2.8V234.4L117.7,234.4z"/>
		</g>
	</g>
</g>
    
  </svg>
);
