import * as React from 'react';
import { graphql, EntityConfig, entityStandardFormFields, PostDetailsLayout, PostDetailsBanner, EntityWithTaxonomiesCard } from '@shapeable/core';
import { Reflection as MyType } from '@shapeable/villars-types';

export const Reflection: EntityConfig<MyType> = {
  name: 'Reflection',
  canEmbed: true,
  groupName: 'Participate',
  autoRoutes: ['details', 'create', 'update', 'list'],
  useConnections: true,
  components: {
    details: PostDetailsLayout,
    banner: PostDetailsBanner,
    card: EntityWithTaxonomiesCard,
  },
  banner: {
    queryFields: graphql`
      subtitle
    `
  },
  filters: {
    fields: {
      // types: { entityTypeName: 'ReflectionType' },
      challenges: { entityTypeName: 'Challenge' },
      trends: { entityTypeName: 'Trend' },
      topics: { entityTypeName: 'Topic' },
      subTopics: { entityTypeName: 'SubTopic' },
      sites: { entityTypeName: 'Site' },
    },
  },
  includeInSearch: true,
  table: {
    fieldNames: ["info", "path", "updatedBy", "createdBy"]
  },
  list: {
    resultsVariant: 'grid',
    hasSearch: true,
    // Remove _DEFINITION_ page
    applyFilterState: (state) => {
      return {
        ...(state || {}),
        filter: {
          name: { ne: "_DEFINITION_" },
          ...(state.filter || {}),
        },
      };
    },
    queryFields: graphql`
      openGraph { 
        id
        description { id html text plain plainTruncated: plain(truncate: { words: 25 }) }
        date
        url
        author
        image { id url }
       }
       ...TaxonomyFields
      sites { ...EntityValueFields }
      summary { id text }
      updatedBy { ...CreatorFields }
      createdBy { ...CreatorFields }
      path
    `,
  },
  details: {
    queryFields: graphql`
      summary { id text }
      path
      title
      subtitle
      pretitle
      content { ...ContentNodeFields }
      intro { ...ContentNodeFields }
      outro { ...ContentNodeFields }

      ...TaxonomyFields

      types { ...EntityValueFields }
      authors { ...EntityValueFields }
      people { ...EntityValueFields }
      imageAssets { ...EntityValueFields }
      organisations { ...EntityValueFields }
      feedEntries { ...EntityValueFields }
      sites { ...EntityValueFields url }
    `,
  },

  form: {
    modal: {
      fill: true,
    },
    fieldSets: [
      { name: 'overview' },
      { name: 'content' },
      { name: 'titles' },
      { name: 'taxonomies', label: process.env.TAXONOMIES_LABEL || 'Challenges & Goals' },
      // { name: 'library' },
      // { name: 'community' },
      { name: 'fr', label: "Français" },
      { name: 'de', label: 'Deutsch' },
      { name: 'options' },
    ],
    fields: {
      ...entityStandardFormFields,
      authors: { type: 'linkedMultiple', label: 'Authors', entityTypeName: 'Person', fieldSet: 'overview', description: 'Who is (are) the author(s) of this {{REFLECTION}}?' },
      completed: { type: 'boolean', label: 'Completed?', description: 'Check this box to mark your {{REFLECTION}} as Completed and Ready to Post', fieldSet: 'overview' },
      published: { type: 'date', updateArgs: 'auto: false', description: 'When was this {{REFLECTION}} published? Note: If not specified, the last update of this {{REFLECTION}} will be taken as the publish date', recommended: false },
      edited: { type: 'date', fieldSet: 'overview', description: 'When was this {{REFLECTION}} last edited? Note: the record will maintain an Updated date automatically, but this is useful if you\'d like to curate a last edited date for display on a website for example.', recommended: false },
      types: { type: 'linkedMultiple', entityTypeName: "ReflectionType", specOnly: true },
      banners: { type: 'banner', isMulti: true, fieldSet: 'overview', recommended: false, description: 'Provide one or more {{BANNERS}} for this {{REFLECTION}}, if required' },
      sites: { type: 'linkedMultiple', fieldSet: 'admin', recommended: false, label: 'Websites', entityTypeName: 'Site', description: 'Which {{SITES}} should this page appear in? (Note: this does not automatically setup a {{SITE}}, its purpose is to provide the ability for {{SITE}} developers to include this {{REFLECTION}} in the {{SITES}} specified here)' },
      summary: { type: 'longText', height: '40px',  description: 'Provide a concise summary of this {{REFLECTION}} which is shown in cards and listings (this takes priority over Intro and Content for listings).', recommended: false },
      intro: { type: 'longText', height: '80px', recommended: false, fieldSet: 'content' },
      content: { type: 'longText', height: '300px', recommended: true, fieldSet: 'content' },
      outro: { type: 'longText', height: '80px', fieldSet: 'content', recommended: false },
      title: { type: 'text', fieldSet: 'content', description: 'Provide an alternative title to use if the name field is not sufficient.' },
      subtitle: { type: 'text', fieldSet: 'content', description: 'Provide a subtitle if required. This is useful if the overall title needs to be broken into two parts.' },
      pretitle: { type: 'text', fieldSet: 'content', description: 'Provide a title to display before the actual title. This might be useful for text which groups multiple pages into a section, where you still want the title field to represent the actual page title.' },

      feedEntries: { type: 'linkedMultiple', label: 'Articles', entityTypeName: 'FeedEntry', fieldSet: 'library', description: 'Does this {{REFLECTION}} have any related {{FEED_ENTRIES}}?' },
      imageAssets: { type: 'linkedMultiple', entityTypeName: 'ImageAsset', fieldSet: 'library' },
      videos: { type: 'linkedMultiple', entityTypeName: 'Video', fieldSet: 'library' },
      people: { type: 'linkedMultiple', entityTypeName: 'Person', fieldSet: 'community' },
      organisations: { type: 'linkedMultiple', entityTypeName: 'Organisation', fieldSet: 'community' },

      challenges: { type: 'linkedMultiple', entityTypeName: 'Challenge', fieldSet: 'taxonomies' },
      keyIssues: { type: 'linkedMultiple', entityTypeName: 'KeyIssue', fieldSet: 'taxonomies' },
      trends: { type: 'linkedMultiple', entityTypeName: 'Trend', fieldSet: 'taxonomies' },
      topics: { type: 'linkedMultiple', entityTypeName: 'Topic', fieldSet: 'taxonomies' },
      subTopics: { type: 'linkedMultiple', entityTypeName: 'SubTopic', fieldSet: 'taxonomies' },

      name_fr: { type: 'text', fieldSet: 'fr', label: 'Name', badge: "Français" },
      imageAssets_fr: { type: 'linkedMultiple', entityTypeName: "ImageAsset", fieldSet: 'fr', label: 'Image Assets', badge: "Français" },
      intro_fr: { type: 'longText', fieldSet: 'fr', label: 'Intro', badge: "Français" },
      summary_fr: { type: 'longText', fieldSet: 'fr', label: 'Summary', badge: "Français" },
      content_fr: { type: 'longText', fieldSet: 'fr', label: 'Content', badge: "Français" },
      outro_fr: { type: 'longText', fieldSet: 'fr', label: 'Outro', badge: "Français" },
      title_fr: { type: 'text', fieldSet: 'fr', label: 'Title', badge: "Français" },
      subtitle_fr: { type: 'text', fieldSet: 'fr', label: 'Subtitle', badge: "Français" },
      pretitle_fr: { type: 'text', fieldSet: 'fr', label: 'Pretitle', badge: "Français" },

      name_de: { type: 'text', fieldSet: 'de', label: 'Name', badge: "Deutsch" },
      imageAssets_de: { type: 'linkedMultiple', entityTypeName: "ImageAsset", fieldSet: 'de', label: 'Image Assets', badge: "Deutsch" },
      intro_de: { type: 'longText', fieldSet: 'de', label: 'Intro', badge: "Deutsch" },
      summary_de: { type: 'longText', fieldSet: 'de', label: 'Summary', badge: "Deutsch" },
      content_de: { type: 'longText', fieldSet: 'de', label: 'Content', badge: "Deutsch" },
      outro_de: { type: 'longText', fieldSet: 'de', label: 'Outro', badge: "Deutsch" },
      title_de: { type: 'text', fieldSet: 'de', label: 'Title', badge: "Deutsch" },
      subtitle_de: { type: 'text', fieldSet: 'de', label: 'Subtitle', badge: "Deutsch" },
      pretitle_de: { type: 'text', fieldSet: 'de', label: 'Pretitle', badge: "Deutsch" },

    },
  },
  
}; 